import React from 'react';

import LeavePhoneForm from '@components/leave-phone-form/leave-phone-form';

import * as s from './contact-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

import Phone from '@assets/images/icons/phone_2.svg';
import Mail from '@assets/images/icons/mail.svg';
import Tag from '@assets/images/icons/tag.svg';
import Organisation from '@assets/images/icons/organisation.svg';


const ContactSection = () => {



    return (
        <section className={`${s.contact_section} ${s_wrapper.apply} ${s_wrapper.apply_padding} ${s_wrapper.apply_height}`}>

            <h2>Kontakt</h2>


            <p className={`${s.info}`}>
                Jeśli interesuje Cię leasing, kredyt lub wynajem?
                Szukasz najlepszej oferty? Chcesz podzielić się uwagami dotyczącymi naszej pracy czy firmy?
                Wypełnij poniższy formularz. Na pewno oddzwonimy!
            </p>

            <address>
                <div>
                    <div className={s.item}>
                        <img src={Phone} alt="Telefon ikona" />
                        <a href="tel:696490941" title='Zadzwoń'>696 490 941</a>
                    </div>
                    <div className={s.item}>
                        <img src={Mail} alt="Mail ikona" />
                        <a href="mailto:pawel.loczewski@clicklease.pl" title='Napisz maila'>pawel.loczewski@clicklease.pl</a>
                    </div>
                    <div className={s.item}>
                        <img src={Organisation} alt="Siedziba ikona" />
                        <span>
                            ClickLease Oddział Warszawa<br/>
                            ul. Bluszczańska 50/1<br/>
                            00-712 Warszawa
                        </span>
                    </div>
                </div>

                <div>
                    <div className={s.item}>
                        <img src={Tag} alt="Tag ikona" />
                        <span>NIP: 631-26-91-342</span>
                    </div>
                    <div className={s.item}>
                        <img src={Tag} alt="Tag ikona" />
                        <span>KRS: 0000841993</span>
                    </div>
                    <div className={s.item}>
                        <img src={Tag} alt="Tag ikona" />
                        <span>
                            Numer KNF: <br/>
                            RPK033805
                        </span>
                    </div>
                </div>
            </address>


            <LeavePhoneForm classes={s.phone_form} />


        </section>
    )
};

export default ContactSection;
