import React from 'react';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';

const PrivacyPolicy = () => {

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing samochodów nowych i aut używanych'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <h1>Leasing samochodów nowych i aut używanych</h1>

          <div className={s_text.double}>
            <div>
              <p>
                Dla tych, którzy cenią sobie wygodę, ale nie koniecznie najważniejszy jest dla nich przesyt
                dóbr materialnych, mamy leasing na samochód używany. Korzyści bez liku, a nie trzeba ponosić
                wysokich kosztów zakupu. Możliwość „wrzucenia” kosztów, które możesz odliczyć od podatku,
                płynność finansowa, przystępne raty… Ty szukasz samochodu na miarę swoich oczekiwań, a my
                dokładamy wszelkich starań aby finansowanie było na przystępnym poziomie. A jeśli swoje
                dochody uzyskujesz sezonowo? Dla nas to żaden problem, możemy tak dobrać okres finansowania
                abyś czuł się bezpiecznie. To ty zdecydujesz kiedy zapłacisz więcej, a kiedy mniej.
                To od Cebie zależy na jakie koszty możesz sobie pozwolić. A może jesteś fanem „starych samochodów” i szukasz
                dla siebie „wisienki na torcie” To też nie problem, nie wiemy co to „ograniczenia wiekowe”, spełniamy
                marzenia i realizujemy plany.
              </p>
            </div>
            <div>
              <h2>Finansujemy:</h2>
              <ul className={s_text.checkmark_list}>
                <li>Samochody nowe - uwaga mamy duże rabaty</li>
                <li>samochody używane do 12 lat.</li>
              </ul>
            </div>
          </div>

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
